.button-register {
    width: 300px;
    height: 150px;
    position: fixed;
    bottom: 30px;
    right: -50px;
    color: white;
    border-color: transparent;
    border-radius: 5px;
    transition: all 0.5s ease;
    z-index: 99;
}

.button-register:hover {
    border-radius: 5px;
    animation: fade-up 1s infinite linear;
}

.title-slogan {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
}

.title-candidate {
    font-size: 22px;
    color: #027F52;
}

.footer-about-slogan {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    color: #027F52;
}

.description-list {
    margin-bottom: 14px;
    padding-left: 25px;
}

.description-list::before {
    font-family: Material Design Icons;
    content: '\F0E1E';
    position: absolute;
    color: green;
    left: 12px;
}